<template>
  <v-tabs-items v-model="selectedAppBarTab">
    <v-tab-item
      v-for="tab in tabs"
      :value="tab.key"
      :key="tab.key"
    >
      <v-container fill-height>
        <v-row>
          <v-col cols="8" />
          <v-col cols="4">
            <v-row>
              <v-col cols="6">
                <v-btn
                  :href="'mailto:' + user.email"
                  class="mb-5"
                  color="green"
                  block
                >
                  {{ $t('email') }}
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  class="mb-5"
                  color="red"
                  block
                >
                  {{ $t('block') }}
                </v-btn>
              </v-col>
            </v-row>
            <UserForm v-model="user" />
            <v-btn
              class="mt-5"
              block
              color="primary"
            >
              {{ $t('update') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-tab-item>
  </v-tabs-items>
</template>

<script>

import UserForm from "@/components/UserForm"

export default {
    name: 'User',
    components: {
        UserForm
    },
    data: function() {
        return {
            user: null
        }
    },
    computed: {
        selectedAppBarTab: function() {
            return this.$store.state.selectedAppBarTab
        },
        tabs: function() {

            const tabs = []

            tabs.push({
                title: this.$t('profile'),
                key: 'profile'
            })

            return tabs
        }
    },
    beforeMount() {
        this.user = this.$store.state.users.find(user => user.uid === this.$route.params.uuid)
    },
    mounted() {
        this.$store.commit('updateAppBarTabs', this.tabs)
        this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
        this.$store.commit('updateAppBarTitle', this.user.displayName)
    }
}

</script>